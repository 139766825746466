import { MultiStepCol } from "../../../../../addEditPopup/multiStepCol";
import { FormControlLabel, FormGroup, IconButton } from "@mui/material";
import { useState } from "react";
import { CheckboxWithBlueCheck } from "../../../../../../ui/checkbox/checkboxWithBlueTick";
import { CheckboxList } from "../../../../CheckboxList";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { DateSelector } from "../../../../../../ui/pickers/date";
import { validations } from "../../../../../../../helper/validations";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { sortAtoZ } from "../../../../../../../helper/constants";
import moment from "moment";

export const OfferForm = ({
  openForm,
  toggleForm,
  activeStep,
  setActiveStep,
  formValues,
  setFormValues,
  handleSaveForm,
  formErrors,
  setFormErrors,
  isSubmitting,
  selectedSuitCase,
  editMode,
}) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [allSupervisors, setAllSupervisors] = useState([]);
  const [rateTypes, setRateTypes] = useState([]);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 14));
  const [expireDate, setExpireDate] = useState(
    new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 3)
  );
  const [supervisorList, setSupervisorList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const errors = {};

  useEffect(() => {
    getAllApiData();
    getAllSupervisors();
    if (editMode === false && openForm === true) {
      setFormValues({
        ...formValues,
        ["startDate"]: startDate,
        ["expireDate"]: expireDate,
      });
      getJobDetails();
    }
  }, []);

  useEffect(() => {
    if (activeStep === 2 && !formValues?.startDate && !formValues?.expireDate) {
      setFormValues({
        ...formValues,
        startDate: startDate,
        expireDate: expireDate,
      });
    }
  }, [activeStep]);

  const getJobDetails = () => {
    Api(
      "GET",
      `Job/GetJobDetailsForOfferingForm?applicantId=${selectedSuitCase.applicantId}&companyId=${selectedSuitCase.companyId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setFormValues({
          rate: res.data.jobRateValue,
          rateTypeId: res.data.rateTypeId,
          shiftTypeId: res.data.shiftTypeId,
          scheduleTypeId: res.data.scheduleTypeId,
          employmentTypeId: res.data.employmentTypeId,
        });
      })
      .catch((err) => console.log(err));
  };

  const getAllApiData = () => {
    setLoading(true);
    Api(
      "GET",
      `Sources/GetSourceList?type=all&companyId=${selectedSuitCase.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        const sourcesData = res.data.reduce(function (rv, x) {
          (rv[x["type"]] = rv[x["type"]] || []).push(x);
          return rv;
        }, {});
        const rData = sortAtoZ(sourcesData?.ratetypes, "name");
        setRateTypes(rData);

        const sData = sortAtoZ(sourcesData?.shifttypes, "name");
        setShiftTypes(sData);

        const stData = sortAtoZ(sourcesData?.scheduletypes, "name");
        setScheduleTypes(stData);

        const eData = sortAtoZ(sourcesData?.employmenttypes, "name");
        setEmploymentTypes(eData);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllSupervisors = () => {
    Api(
      "GET",
      `Job/GetTypeWiseUserList?companyId=${selectedSuitCase.companyId}&type=1`
    )
      .then((res) => {
        const data = [...res.data];
        data.map((rec) => {
          rec.id = rec.userId;
          rec.name = rec.userFirstName + " " + rec.userLastName;
          rec.checked = false;
        });

        formValues?.supervisors?.forEach((element) => {
          const index = data.findIndex((el) => el.userId === element.sId);
          if (index > -1) {
            data[index].checked = true;
          }
        });
        setAllSupervisors(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    handleSaveForm();
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setFormErrors(firstScreen(formValues));
      if (Object.keys(firstScreen(formValues)).length > 0) {
        return false;
      }
      return true;
    }

    if (activeStep === 1) {
      setFormErrors(secondScreen(formValues));
      if (Object.keys(secondScreen(formValues)).length > 0) {
        return false;
      }
      return true;
    }

    if (activeStep === 2) {
      setFormErrors(thirdScreen(formValues));
      if (Object.keys(thirdScreen(formValues)).length > 0) {
        return false;
      }
      return true;
    }
  };

  const firstScreen = (values) => {
    if (!values.rate) {
      errors.rate = validations.rate.req;
    }

    if (!values.rateTypeId) {
      errors.rateTypeId = validations.rateTypeId.req;
    }

    if (!values.shiftTypeId) {
      errors.shiftTypeId = validations.shiftTypeId.req;
    }

    if (!values.scheduleTypeId) {
      errors.scheduleTypeId = validations.scheduleTypeId.req;
    }

    if (!values.employmentTypeId) {
      errors.employmentTypeId = validations.employmentTypeId.req;
    }

    return errors;
  };

  const secondScreen = (values) => {
    if (
      (!values.supervisors || values.supervisors.length === 0) &&
      (!values.supervisorsList || values.supervisorsList.length === 0)
    ) {
      errors.supervisors = "Please select at least one supervisor!";
    }

    return errors;
  };

  const thirdScreen = (values) => {
    if (!values.startDate) {
      errors.startDate = validations.startDate.req;
    }

    if (!values.expireDate) {
      errors.endDate = validations.endDate.req;
    }

    if (!!values.expireDate) {
      if (
        moment(values.expireDate) >
        moment(new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 19))
      ) {
        errors.endDate = validations.endDate.max;
      }
      if (moment(values.expireDate) < moment()) {
        errors.endDate = validations.endDate.min;
      }
    }

    return errors;
  };

  const handleCheckboxChange = (checkName, isChecked) => {
    const users = [];
    const data = [...allSupervisors];
    data.map((rec) => {
      if (rec.id === checkName) {
        rec.checked = isChecked;
      }
    });
    setAllSupervisors(data);
    data.filter((d) => {
      if (d.checked === true) {
        users.push(d.id);
      }
    });

    setFormValues({ ...formValues, ["supervisors"]: users });
  };

  const handleStartChange = (newValue) => {
    setStartDate(newValue.toISOString());
    setFormValues({ ...formValues, ["startDate"]: newValue.toISOString() });
  };

  const handleExpireChange = (newValue) => {
    setExpireDate(newValue.toISOString());
    setFormValues({ ...formValues, ["expireDate"]: newValue.toISOString() });
  };

  const handleDraft = (e) => {
    setFormValues({ ...formValues, ["isSaveAsDraft"]: e.target.checked });
  };

  const handleExempt = (e) => {
    setFormValues({
      ...formValues,
      ["isExemptfromovertime"]: e.target.checked,
    });
  };

  const handleAddBox = () => {
    setSupervisorList([
      ...supervisorList,
      {
        firstName: "",
        lastName: "",
        title: "",
        emailAddress: "",
      },
    ]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const data = [...supervisorList];
    data.at(index)[name] = value;
    setSupervisorList(data);
    setFormValues({ ...formValues, ["supervisorsList"]: data });
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...supervisorList];
    list.splice(index, 1);
    setSupervisorList(list);
    setFormValues({ ...formValues, ["supervisorsList"]: list });
  };

  return (
    <MultiStepCol
      openForm={openForm}
      toggleForm={toggleForm}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleSubmit={handleSubmit}
      handleNext={handleNext}
      applicantId={selectedSuitCase.applicantId}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      title="Offering Form"
      steps="3"
    >
      {activeStep === 0 && (
        <>
          <div className="forms-input">
            <label>Rate:</label>
            <input
              type="text"
              name="rate"
              value={formValues?.rate}
              placeholder="Rate"
              onChange={handleChange}
            />
            <span className="error_msg">{formErrors?.rate}</span>
          </div>

          <div className="forms-input">
            <label>Rate Type:</label>
            <select
              name="rateTypeId"
              id="rate_type"
              onChange={handleChange}
              value={formValues?.rateTypeId}
            >
              <option>Select</option>
              {rateTypes?.map((rate) => (
                <option value={rate.sourceId} key={rate.sourceId}>
                  {rate.name}
                </option>
              ))}
            </select>
            <span className="error_msg">{formErrors?.rateTypeId}</span>
          </div>

          <div className="forms-input">
            <label>Shift Type:</label>
            <select
              name="shiftTypeId"
              id="shift_type"
              onChange={handleChange}
              value={formValues?.shiftTypeId}
            >
              <option>Select</option>
              {shiftTypes?.map((shift) => (
                <option value={shift.sourceId} key={shift.sourceId}>
                  {shift.name}
                </option>
              ))}
            </select>
            <span className="error_msg">{formErrors?.shiftTypeId}</span>
          </div>

          <div className="forms-input">
            <label>Schedule Type:</label>
            <select
              name="scheduleTypeId"
              id="schedule_type"
              onChange={handleChange}
              value={formValues?.scheduleTypeId}
            >
              <option>Select</option>
              {scheduleTypes?.map((schedule) => (
                <option value={schedule.sourceId} key={schedule.sourceId}>
                  {schedule.name}
                </option>
              ))}
            </select>
            <span className="error_msg">{formErrors?.scheduleTypeId}</span>
          </div>

          <div className="forms-input">
            <label>Employment Type:</label>
            <select
              name="employmentTypeId"
              id="emp_type"
              onChange={handleChange}
              value={formValues?.employmentTypeId}
            >
              <option>Select</option>
              {employmentTypes?.map((emp) => (
                <option value={emp.sourceId} key={emp.sourceId}>
                  {emp.name}
                </option>
              ))}
            </select>
            <span className="error_msg">{formErrors?.employmentTypeId}</span>
          </div>

          <div className="ml-10 forms-input">
            <FormGroup>
              <FormControlLabel
                control={
                  <CheckboxWithBlueCheck
                    disableRipple
                    checked={
                      formValues?.isExemptfromovertime == true ? true : false
                    }
                    value="isExemptfromovertime"
                    key="isExemptfromovertime"
                    onChange={handleExempt}
                  />
                }
                label="Exempt from overtime"
              />
            </FormGroup>
          </div>
        </>
      )}

      {activeStep === 1 && (
        <>
          <h4>Select Supervisors</h4>
          <span className="error_msg">{formErrors?.supervisors}</span>
          <CheckboxList
            allData={allSupervisors}
            setAllData={setAllSupervisors}
            showSaveContainer={false}
            handleChange={handleCheckboxChange}
          />

          {supervisorList.map((participant, index) => (
            <div className="forms-input" key={index}>
              <div className="add-upload-container" key={1}>
                <div className="text-right">
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveClick(index)}
                  >
                    <CloseIcon color="primary" fontSize="small" />
                  </IconButton>
                </div>
                <div className="forms-input sections">
                  <div className="col-2">
                    <label>First Name:</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      id="firstName"
                      value={participant.firstName}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>

                  <div className="col-2">
                    <label>Last Name:</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      id="lastName"
                      value={participant.lastName}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>
                </div>

                <div className="forms-input sections">
                  <div className="col-2">
                    <label>Title:</label>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      id="title"
                      value={participant.title}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>

                  <div className="col-2">
                    <label>Email Address:</label>
                    <input
                      type="text"
                      placeholder="Email Address"
                      name="emailAddress"
                      id="emailAddress"
                      value={participant.email}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          <span className="participants pointer" onClick={handleAddBox}>
            <AddIcon fontSize="small" /> Add Supervisor
          </span>
        </>
      )}

      {activeStep === 2 && (
        <>
          <div className="forms-input">
            <label>Start Date :</label>
            <DateSelector
              value={formValues?.startDate ? formValues?.startDate : startDate}
              setValue={setStartDate}
              disablePast={true}
              handleDateChange={handleStartChange}
            />
            <span className="error_msg">{formErrors?.startDate}</span>
          </div>

          <div className="forms-input">
            <label>Message to Applicant :</label>
            <textarea
              name="applicantMessage"
              placeholder="Message to applicant"
              onChange={handleChange}
              value={formValues?.applicantMessage}
            ></textarea>
          </div>

          <div className="forms-input">
            <label>Expires :</label>
            <DateSelector
              setValue={setExpireDate}
              disablePast={true}
              value={
                formValues?.expireDate ? formValues?.expireDate : expireDate
              }
              handleDateChange={handleExpireChange}
              maxDate={
                new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 19)
              }
            />
            <span className="error_msg">{formErrors?.endDate}</span>
          </div>

          <div className="ml-10 forms-input">
            <FormGroup>
              <FormControlLabel
                control={
                  <CheckboxWithBlueCheck
                    disableRipple
                    checked={!!formValues?.isSaveAsDraft}
                    onChange={handleDraft}
                    value="isSaveAsDraft"
                    key="isSaveAsDraft"
                  />
                }
                label="Save as Draft"
              />
            </FormGroup>
          </div>
        </>
      )}
    </MultiStepCol>
  );
};
